var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form-item',{attrs:{"label":"优惠折扣","rules":[
      {
        required: true,
        message: '请输入优惠折扣',
        trigger: 'blur'
      }
    ]}},[_c('el-col',{attrs:{"md":9}},[_c('el-input',{attrs:{"type":"number","step":"0.01","min":0.01},model:{value:(_vm.formModel.zk.discount),callback:function ($$v) {_vm.$set(_vm.formModel.zk, "discount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formModel.zk.discount"}})],1)],1),_c('el-form-item',{attrs:{"label":"最高抵现","rules":[
      {
        required: true,
        message: '请输入最高抵现',
        trigger: 'blur'
      }
    ]}},[_c('el-col',{attrs:{"md":9}},[_c('el-input',{attrs:{"type":"number","min":1},model:{value:(_vm.formModel.zk.max_price),callback:function ($$v) {_vm.$set(_vm.formModel.zk, "max_price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formModel.zk.max_price"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }