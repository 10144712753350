var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form-item',{attrs:{"label":"优惠金额","rules":[
        {
          required: true,
          message: '请输入优惠金额',
          trigger: 'blur'
        }
      ]}},[_c('el-col',{attrs:{"md":9}},[_c('el-input',{attrs:{"type":"number","step":"0.01","min":0.01},model:{value:(_vm.formModel.dx.yh_price),callback:function ($$v) {_vm.$set(_vm.formModel.dx, "yh_price", _vm._n($$v))},expression:"formModel.dx.yh_price"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"b-tips",staticStyle:{"margin-left":"140px"}},[_vm._v("不填为无门槛")])]),_c('el-form-item',{attrs:{"label":"满减条件","rules":[{ required: true,
          message: '请填写满减条件',trigger: 'blur'}]}},[_c('el-col',{attrs:{"md":9}},[_c('el-input',{attrs:{"type":"number","step":"0.01","min":0.01},model:{value:(_vm.formModel.dx.reduce_price),callback:function ($$v) {_vm.$set(_vm.formModel.dx, "reduce_price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formModel.dx.reduce_price"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }