<template>
  <div>
    <el-form-item
      label="优惠劵价值"
    >
      <el-col :md="9">
        <el-input
          type="number"
          :min="1"
          v-model.trim="formModel.sp.price"
        ></el-input>
      </el-col>
    </el-form-item>
    <el-form-item
      label="赠送商品"
    >
      <el-radio-group v-model="formModel.sp.type">
        <div class="sp-i">
          <el-radio :label="1">同价位商品</el-radio>
          <el-input-number
            v-model="num.jw_sum"
            @change="JwnumChange"
            :step="1"
            step-strictly
            placeholder="赠送数量"
          ></el-input-number>
        </div>
        <div class="sp-i">
          <el-radio :label="2">同品类商品</el-radio>
          <el-input-number
            v-model="num.pl_sum"
            @change="PlnumChange"
            :step="1"
            step-strictly
            placeholder="赠送数量"
          ></el-input-number>
        </div>
        <div class="sp-i">
          <el-radio :label="3">指定商品</el-radio>
           <el-select
            v-model="shopId"
            @change="shopIds"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in formModel.cs.shop"
              :key="item.id"
              :label="item.product_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
           <el-input-number
            v-model="num.zd_sum"
            @change="ZdnumChange"
            :step="1"
            step-strictly
            placeholder="赠送数量"
          ></el-input-number>
        </div>
      </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'SP',
  props: {
    formModel: {
      type: Object
    }
  },
  data: function () {
    return {
      spType: '',
      num: {
        jw_sum: 1, // 同价位
        pl_sum: 1, // 品类
        zd_sum: 1// 指定
      },
      shopId: ''
    }
  },
  mounted () {
    this.shopId = this.formModel.sp.shop_ids
    if (this.formModel.sp.type === 1) {
      this.num.jw_sum = this.formModel.sp.sum
    }
    if (this.formModel.sp.type === 2) {
      this.num.pl_sum = this.formModel.sp.sum
    }
    if (this.formModel.sp.type === 3) {
      this.num.zd_sum = this.formModel.sp.sum
    }
  },
  methods: {
    ZdnumChange (e) {
      this.formModel.sp.sum = this.num.zd_sum
    },
    PlnumChange (e) {
      this.formModel.sp.sum = this.num.pl_sum
    },
    JwnumChange (e) {
      this.formModel.sp.sum = this.num.jw_sum
    },
    shopIds () {
      this.formModel.sp.shop_ids = this.shopId
    }
  }
}
</script>
<style lang='scss' scoped>
.sp-i {
    margin-bottom: 50px;
}
</style>
