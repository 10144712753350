<template>
  <div>
    <el-form-item
      label="总劵额"
    >
      <el-col :md="9">
        <el-input
          type="number"
          step="0.01"
          :min="0.01"
          v-model.trim="formModel.hd.price"
        ></el-input>
      </el-col>
    </el-form-item>
    <el-form-item
      label="瓜分人数"
    >
      <el-col :md="9">
        <el-input
          type="number"
          :min="1"
          v-model.trim="formModel.hd.people"
        ></el-input>
      </el-col>
    </el-form-item>
    <el-form-item
      label="瓜分方式"
    >
      <el-radio-group v-model="formModel.hd.type">
        <el-radio :label="1">平均分配</el-radio>
        <el-radio :label="2">随机分配</el-radio>
      </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'HD',
  props: {
    formModel: {
      type: Object
    }
  }
}
</script>
<style lang='' scoped>
</style>
