<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="140px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >
    <el-form-item
      label="优惠劵类型"
      prop="type"
    >
      <template>
        <el-radio-group v-model="formModel.type" @change="changeType">
          <el-radio :label="1" :disabled='isEdit'>抵现劵</el-radio>
          <el-radio :label="2" :disabled='isEdit'>折扣劵</el-radio>
          <el-radio :label="3" :disabled='true'>商品劵</el-radio>
          <el-radio :label="4" :disabled='true'>活动劵</el-radio>
        </el-radio-group>
      </template>
    </el-form-item>

    <el-form-item label="适用范围">
      <template>
        <el-radio-group v-model="formModel.scoped_id">
          <el-radio :label="1">全场适用</el-radio>
          <el-radio :label="2">品类适用</el-radio>
          <el-select
            v-model="classifyId"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in formModel.cs.classify"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-radio
            :label="3"
            style="margin-left:20px;"
          >单品适用</el-radio>
          <el-select
            v-model="shopId"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in formModel.cs.shop"
              :key="item.id"
              :label="item.product_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-radio-group>
      </template>
    </el-form-item>

    <el-form-item
      label="会员折上折"
      prop="member_discount"
    >
      <template>
        <el-radio-group v-model="formModel.member_discount">
          <el-radio :label="1">可叠加</el-radio>
          <el-radio :label="2">不可叠加</el-radio>
        </el-radio-group>
      </template>

    </el-form-item>

    <el-form-item
      label="是否外卖可用"
      prop="is_wm_use"
    >
      <template>
        <el-radio-group v-model="formModel.is_wm_use">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </template>

    </el-form-item>

    <el-form-item
      label="小程序优惠劵显示标题"
      prop="coupon_des"
    >
      <el-col :md="9">
        <el-input
          type="text"
          v-model.trim="formModel.coupon_des"
          :max="1"
        ></el-input>
        <span>此处在积分商品建立时，以积分商品的名称为准，该处失效。</span>
      </el-col>
    </el-form-item>

    <el-form-item
      label="优惠劵名称"
      prop="name"
    >
      <el-col :md="9">
        <el-input
          type="text"
          v-model.trim="formModel.name"
        ></el-input>
      </el-col>
    </el-form-item>

     <el-form-item
      label="购买数量限制"
      prop="shop_limit"
    >
      <el-col :md="9">
        <el-input
          type="number"
          v-model.trim="formModel.shop_limit"
          :min="0"
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item
      label="可用时段"
      prop="startTime"
    >
      <el-time-picker
        is-range
        v-model="formModel.startTime"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="选择时间范围"
        format="HH:mm"
        value-format="HH:mm"
      >
      </el-time-picker>
    </el-form-item>

    <el-form-item
      label="可用周期"
      prop="cycle_type"
    >
      <template>
        <el-radio-group v-model="formModel.cycle_type">
          <el-radio :label="1">每天可用</el-radio>
          <el-radio :label="2">每周可用</el-radio>
          <el-select
            v-model="weeklyId"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in weeklyIds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-radio
            :label="3"
            style="margin-left:20px;"
          >每月可用</el-radio>
        </el-radio-group>
        <el-select
          v-model="monthlyId"
          multiple
          collapse-tags
          style="margin-left: 20px;"
          placeholder="请选择"
        >
          <el-option
            v-for="item in monthlyIds"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </template>
    </el-form-item>

    <el-col :span="10">
      <div
        class="b-tips"
        style="margin-left: 140px;"
      >此优惠券需暂停使用的特定日期 //例子:2000-01-01</div>
    </el-col>
    <el-form-item
      label="跳过日期"
      prop="couponName"
    >
      <el-col :span="18">
        <el-select
          style="width:100%"
          v-model="formModel.jump_time"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="请输入跳出时间"
        >
        </el-select>
        <el-option
          v-for="item in []"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-col>
    </el-form-item>
    <div class="b-line"></div>
    <!-- 动态类型选项数据 -->
    <template>
      <dx
        v-if="formModel.type === 1"
        :formModel="formModel"
      />
      <zk
        v-if="formModel.type === 2"
        :formModel="formModel"
      />
      <sp
        v-if="formModel.type === 3"
        :formModel="formModel"
      />
      <hdj
        v-if="formModel.type === 4"
        :formModel="formModel"
      />
    </template>
    <!-- 尾巴 -->
    <el-form-item
      label="开启发放"
      prop="开启发放"
    >
      <template>
        <el-radio-group v-model="formModel.private">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </template>
    </el-form-item>
    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import ZK from './ZK'
import DX from './DX'
import SP from './SP'
import HDJ from './HDJ'

export default {
  components: {
    zk: ZK,
    dx: DX,
    sp: SP,
    hdj: HDJ
  },
  name: 'CouponForm',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    const weeklyIds = [
      {
        id: 1,
        name: '周一'
      },
      {
        id: 2,
        name: '周二'
      },
      {
        id: 3,
        name: '周三'
      },
      {
        id: 4,
        name: '周四'
      },
      {
        id: 5,
        name: '周五'
      },
      {
        id: 6,
        name: '周六'
      },
      {
        id: 7,
        name: '周日'
      }
    ]
    const monthlyIds = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
    return {
      submitLoading: false,
      formRules: {
        name: [
          {
            required: true,
            message: '请输入优惠劵名称',
            trigger: 'blur'
          }
        ],
        startTime: [
          {
            required: true,
            message: '请选择时间',
            trigger: 'blur'
          }
        ]
      },
      formModel: null,
      classifyId: [], // 所选分类
      shopId: '', // 所选商品id
      weeklyId: '', // 每周可用 所选
      weeklyIds: weeklyIds, // 每周可用
      startTime: '', // 开始时间结束时间
      monthlyId: '', // 每月
      monthlyIds: monthlyIds // 每月
    }
  },
  mounted () {
    this.formModel = Object.assign(this.model)
    if (this.formModel.scoped_id === 2) {
      this.classifyId = this.formModel.scoped_body
    }
    // 单品适用
    if (this.formModel.scoped_id === 3) {
      this.shopId = this.formModel.scoped_body
    }
    // 可用周期
    if (this.formModel.cycle_type === 2) {
      this.weeklyId = this.formModel.cycle_type_data
    }
    // 可用周期
    if (this.formModel.cycle_type === 3) {
      this.monthlyId = this.formModel.cycle_type_data
    }
  },

  methods: {
    // 改变类型
    changeType (e) {
      if (this.isEdit === true) {
        this.$message.error('你不能更改其他类型了')
        return false
      }
    },
    // 处理数据
    formData () {
      // 品类适用
      if (this.formModel.scoped_id === 2) {
        this.formModel.scoped_body = this.classifyId
      }
      // 单品适用
      if (this.formModel.scoped_id === 3) {
        this.formModel.scoped_body = this.shopId
      }
      // 可用周期
      if (this.formModel.cycle_type === 2) {
        this.formModel.cycle_type_data = this.weeklyId
      }
      // 可用周期
      if (this.formModel.cycle_type === 3) {
        this.formModel.cycle_type_data = this.monthlyId
      }
    },
    handleFormSubmit (formName) {
      this.formData()
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true
        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>

<style lang="scss">
.el-time-spinner__item{
  height:30px !important;
  line-height:30px !important;
}
</style>
