<template>
  <div>
    <el-form-item
      label="优惠金额"
      :rules="[
          {
            required: true,
            message: '请输入优惠金额',
            trigger: 'blur'
          }
        ]"
    >
      <el-col :md="9">
        <el-input
          type="number"
          step="0.01"
          :min="0.01"
          v-model.number="formModel.dx.yh_price"
        ></el-input>
      </el-col>
    </el-form-item>
     <el-col :span="6">
      <div
        class="b-tips"
        style="margin-left: 140px;"
      >不填为无门槛</div>
    </el-col>
    <el-form-item
      label="满减条件"
       :rules="[{ required: true,
            message: '请填写满减条件',trigger: 'blur'}]"
    >
      <el-col :md="9">
        <el-input
          type="number"
          step="0.01"
          :min="0.01"
          v-model.trim="formModel.dx.reduce_price"
        ></el-input>
      </el-col>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'DX',
  props: {
    formModel: {
      type: Object
    }
  }
}
</script>
<style lang='' scoped>
</style>
