<template>
    <div>
        <el-form-item
            label="优惠折扣"
            :rules="[
          {
            required: true,
            message: '请输入优惠折扣',
            trigger: 'blur'
          }
        ]"
        >
            <el-col :md="9">
                <el-input
                    type="number"
                    step="0.01"
                    :min="0.01"
                    v-model.trim="formModel.zk.discount"
                ></el-input>
            </el-col>
        </el-form-item>
        <el-form-item
            label="最高抵现"
             :rules="[
          {
            required: true,
            message: '请输入最高抵现',
            trigger: 'blur'
          }
        ]"
        >
            <el-col :md="
            9"
        >
            <el-input
                type="number"
                :min="1"
                v-model.trim="formModel.zk.max_price"
            ></el-input>
            </el-col>
        </el-form-item>
    </div>
</template>

<script>
export default {
  name: 'ZK',
  props: {
    formModel: {
      type: Object
    }
  }
}
</script>
<style lang='' scoped>
</style>
